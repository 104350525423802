import { globalMiddlewares } from "./middleware";

const routes = [
    {
        path: '/dashboard',
        redirect: '/sale/atendimento'
    },
    {
        path: '/',
        redirect: '/sale/atendimento'
    },
    {
        path: '/favicon.ico',
        redirect: '/sale/atendimento'
    },
    {
        path: '/sale/atendimento',
        name: 'sale-atendimento',
        component: () => import( './components/sales/Atendimento.vue')
    },
    {
        path: '/sale/emissao',
        name: 'sale-emissao',
        component: () => import( './components/sales/Atendimento.vue')
    },
    {
        path: '/sale/nfe',
        name: 'sale-nfe',
        component: () => import( './components/nfe/Nfe.vue')
    },
    {
        path: '/sale/:id',
        name: 'sale-show',
        props: true,
        component: () => import('./components/sales/Sale.vue')
    },
    {
        path: '/dashboards/sales',
        name: 'dashboards-sales',
        props: true,
        component: () => import('./components/dashboard/SalesDashboard.vue')
    },
    {
        path: '/report/sales-discount',
        name: 'report-sales-discount',
        props: true,
        component: () => import('./components/report/SaleDiscount.vue')
    },
    {
        path: '/report/certificate',
        name: 'report-certificate',
        props: true,
        component: () => import('./components/report/Certificate.vue')
    },
    {
        path: '/report/affiliate-sales',
        name: 'report-affiliate-sales',
        component: () => import('./components/report/AffiliateSales.vue')
    },
    {
        path: '/report/commission-adv',
        name: 'report-commission-adv',
        component: () => import('./components/report/CommissionAdv.vue')
    },
    {
        path: '/report/workflow',
        name: 'report-workflow',
        component: () => import('./components/report/Workflow.vue')
    },
    {
        path: '/report/cost',
        name: 'report-cost',
        component: () => import('./components/report/Cost.vue')
    },
    {
        path: '/report/card-error',
        name: 'report-card-error',
        component: () => import('./components/report/CardError.vue')
    },
    {
        path: '/report/campanhas',
        name: 'report-campanhas',
        component: () => import('./components/report/Campaigns.vue')
    },
    {
        path: '/report/dashboard',
        name: 'report-dashboard',
        component: () => import('./components/Dashboard.vue')
    },
    {
        path: '/report/certificate-finished-by-user',
        name: 'report-certificate-finished-by-user',
        component: () => import('./components/report/CertificatesFinishedByUser.vue')
    },
    {
        path: '/notary',
        name: 'notary',
        props: true,
        component: () => import('./components/notary/List.vue')
    },
    {
        path: '/notary/:id',
        name: 'notary-show',
        props: true,
        component: () => import('./components/notary/Show.vue')
    },
    {
        path: '/notary-new',
        name: 'notary-new',
        props: true,
        component: () => import('./components/notary/Insert.vue')
    },
    {
        path: '/documents',
        name: 'documents',
        props: true,
        component: () => import('./components/document/List.vue')
    },
    {
        path: '/documents/:id',
        name: 'documents-show',
        props: true,
        component: () => import('./components/document/Show.vue')
    },
    {
        path: '/customers',
        name: 'customers',
        props: true,
        component: () => import('./components/customer/List.vue')
    },
    {
        path: '/customers/:id',
        name: 'customers-show',
        props: true,
        component: () => import('./components/customer/Customer.vue')
    },
    {
        path: '/customers/:id/commissions',
        name: 'customers-commissions',
        props: true,
        component: () => import('./components/customer/Commission.vue')
    },
    {
        path: '/bank-transfers',
        name: 'bank-transfers',
        props: true,
        component: () => import('./components/bankTransfer/List.vue')
    },
    {
        path: '/leads/dashboard',
        name: 'conversions-dashboard',
        props: true,
        history: false,
        component: () => import('./components/lead/Dashboard.vue')
    },
    {
        path: '/leads',
        name: 'conversions-general',
        props: true,
        history: false,
        component: () => import('./components/lead/List.vue')
    },
    {
        path: '/leads/:track',
        name: 'conversions',
        props: true,
        history: true,
        component: () => import('./components/lead/List.vue')
    },
    {
        path: '/enable-two-factor-auth',
        name: 'enable-two-factor-auth',
        history: true,
        component: () => import('./components/auth/EnableTwoFactor.vue')
    },
    {
        path: '/tracking-pages/campanhas',
        name: 'tracking-campaigns',
        component: () => import('./components/tracking/Campaigns.vue'),
    },
    {
        path: '/tracking-pages/landing-pages',
        name: 'tracking-landing-pages',
        component: () => import('./components/tracking/LandingPages.vue'),
    },
    {
        path: '/tracking-pages/funil',
        name: 'tracking-funnel',
        component: () => import('./components/tracking/Funnel.vue'),
    },
    {
        path: '/tracking-pages/origem-vendas',
        name: 'tracking-sales-origin',
        component: () => import('./components/tracking/SalesOrigin.vue'),
    },
    { path: '*', component: require('./components/NotFound.vue').default },
];

export default routes.map((route) => {
    if (!route.meta) route.meta = {};
    if (!route.meta?.middleware) route.meta.middleware = [];

    Object.keys(globalMiddlewares).forEach((middleware) => {
        route.meta.middleware.push(middleware)
    })

    return route;
});
